import { CASE_DOCUMENTS } from './actionTypes';

export const fetchCaseDocuments = (caseId) => ({
  type: CASE_DOCUMENTS.FETCH.REQUEST,
  payload: {
    caseId,
  },
});

export const fetchCaseDocumentsSuccess = ({ documents, files }) => ({
  type: CASE_DOCUMENTS.FETCH.SUCCESS,
  payload: {
    documents,
    files,
  },
});

export const fetchCaseDocumentsError = (error) => ({
  type: CASE_DOCUMENTS.FETCH.ERROR,
  payload: {
    error,
  },
});

export const clearCaseDocuments = () => ({
  type: CASE_DOCUMENTS.CLEAR,
});
