import { USER } from './actionTypes';

export const getUser = () => ({
  type: USER.GET.REQUEST,
});

export const setUser = (user) => ({
  type: USER.GET.SUCCESS,
  payload: {
    user,
  },
});

export const checkRegistration = (token) => ({
  type: USER.CHECK_REGISTRATION.REQUEST,
  payload: { token },
});

export const checkRegistrationSuccess = ({ email, hasAccount }) => ({
  type: USER.CHECK_REGISTRATION.SUCCESS,
  payload: { email, hasAccount },
});

export const checkRegistrationError = (error) => ({
  type: USER.CHECK_REGISTRATION.ERROR,
  payload: { error },
});

export const clearCheckRegistration = () => ({
  type: USER.CHECK_REGISTRATION.CLEAR,
});

export const getUserError = (error) => ({
  type: USER.GET.ERROR,
  payload: {
    error,
  },
});

export const login = ({ email, password }) => ({
  type: USER.LOGIN.REQUEST,
  payload: {
    email,
    password,
  },
});

export const loginError = (error) => ({
  type: USER.LOGIN.ERROR,
  payload: {
    error,
  },
});

export const register = ({
  email,
  password,
  doesAgreePrivacy,
  doesAgreeCookies,
  token,
}) => ({
  type: USER.REGISTER.REQUEST,
  payload: {
    email,
    password,
    doesAgreePrivacy,
    doesAgreeCookies,
    token,
  },
});

export const registerError = (error) => ({
  type: USER.REGISTER.ERROR,
  payload: {
    error,
  },
});

export const logout = () => ({
  type: USER.LOGOUT.REQUEST,
});

export const logoutSuccess = () => ({
  type: USER.LOGOUT.SUCCESS,
});

export const logoutError = (error) => ({
  type: USER.LOGOUT.ERROR,
  payload: {
    error,
  },
});

export const requestPasswordResetError = (error) => ({
  type: USER.PASSWORD_RESET_REQUEST.ERROR,
  payload: {
    error,
  },
});

export const requestPasswordReset = ({ email }) => ({
  type: USER.PASSWORD_RESET_REQUEST.REQUEST,
  payload: { email },
});

export const requestPasswordResetSuccess = () => ({
  type: USER.PASSWORD_RESET_REQUEST.SUCCESS,
});

export const resetPasswordError = (error) => ({
  type: USER.PASSWORD_RESET.ERROR,
  payload: {
    error,
  },
});

export const resetPassword = ({ token, password }) => ({
  type: USER.PASSWORD_RESET.REQUEST,
  payload: {
    token,
    password,
  },
});

export const resetPasswordSuccess = () => ({
  type: USER.PASSWORD_RESET.SUCCESS,
});

export const clearUser = () => ({
  type: USER.CLEAR,
});
