import { TODOS_LIST } from './actionTypes';

export const fetchTodosList = () => ({
  type: TODOS_LIST.FETCH.REQUEST,
});

export const fetchTodosListSuccess = (todos) => ({
  type: TODOS_LIST.FETCH.SUCCESS,
  payload: {
    todos,
  },
});

export const fetchTodosListError = (error) => ({
  type: TODOS_LIST.FETCH.ERROR,
  payload: {
    error,
  },
});

export const clearTodosList = () => ({
  type: TODOS_LIST.CLEAR,
});
