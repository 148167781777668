import _ from 'lodash';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { selectDocumentById } from 'Documents/models/selectors/documents';

import { CASE_DOCUMENT_PREVIEW } from '../actions/actionTypes';
import {
  fetchCaseDocumentPreviewSuccess,
  fetchCaseDocumentPreviewError,
  downloadCaseDocumentPreviewSuccess,
  downloadCaseDocumentPreviewError,
} from '../actions/caseDocumentPreview';
import { casesApi } from '../api/cases';

function* fetchDocument({ payload: { caseId, documentId } }) {
  try {
    const document = yield call(
      casesApi.fetchCaseDocumentPreview,
      caseId,
      documentId
    );
    yield put(fetchCaseDocumentPreviewSuccess(document));
  } catch (e) {
    yield put(fetchCaseDocumentPreviewError(e.message));
  }
}

function* downloadDocument({ payload: { caseId, documentId } }) {
  try {
    const doc = yield select(selectDocumentById(documentId));
    const response = yield call(casesApi.downloadDocument, caseId, documentId);
    const blob = new Blob([Buffer.from(_.get(response, 'data', []))], {
      type: doc.type,
    });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = _.get(doc, 'name');
    link.click();
    link.remove();
    yield put(downloadCaseDocumentPreviewSuccess());
  } catch (e) {
    yield put(downloadCaseDocumentPreviewError(e.message));
  }
}

const caseDocumentPreviewSagas = [
  takeEvery(CASE_DOCUMENT_PREVIEW.FETCH.REQUEST, fetchDocument),
  takeEvery(CASE_DOCUMENT_PREVIEW.DOWNLOAD.REQUEST, downloadDocument),
];

export default caseDocumentPreviewSagas;
