import { TODOS_OVERVIEW } from './actionTypes';

export const fetchTodosOverview = () => ({
  type: TODOS_OVERVIEW.FETCH.REQUEST,
});

export const fetchTodosOverviewSuccess = (todos) => ({
  type: TODOS_OVERVIEW.FETCH.SUCCESS,
  payload: {
    todos,
  },
});

export const fetchTodosOverviewError = (error) => ({
  type: TODOS_OVERVIEW.FETCH.ERROR,
  payload: {
    error,
  },
});

export const clearTodosOverview = () => ({
  type: TODOS_OVERVIEW.CLEAR,
});
