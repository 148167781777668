import React from 'react';
import { CheckCircleOutlined, CloseCircleFilled } from '@ant-design/icons';

import {
  bigLetterRegex,
  numberRegex,
  eightOrMoreCharactersRegex,
  smallLetterRegex,
} from 'User/utils';

import './PasswordTips.scss';

const PasswordTips = ({ isBlurred, password }) => {
  const renderTip = (regex, text) => (
    <div className='password-instruction-item'>
      {regex.test(password) ? (
        <CheckCircleOutlined className='check-icon' />
      ) : (
        <CloseCircleFilled className='close-icon' />
      )}
      <div
        className={`password-instruction-text${
          regex.test(password) ? ' isFulfilled' : ''
        }`}
      >
        {text}
      </div>
    </div>
  );

  return (
    <div className={`password-tips${isBlurred ? ' isBlurred' : ''}`}>
      {renderTip(smallLetterRegex, 'Ein Kleinbuchstabe')}
      {renderTip(bigLetterRegex, 'Ein Großbuchstabe')}
      {renderTip(numberRegex, 'Eine Zahl')}
      {renderTip(eightOrMoreCharactersRegex, 'Mindestens 8 Zeichen')}
    </div>
  );
};

export default PasswordTips;
