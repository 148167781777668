export const USER = {
  GET: {
    REQUEST: 'USER.GET.REQUEST',
    SUCCESS: 'USER.GET.SUCCESS',
    ERROR: 'USER.GET.ERROR',
  },
  LOGIN: {
    REQUEST: 'USER.LOGIN.REQUEST',
    // we'll use USER.GET.SUCCESS on login success
    ERROR: 'USER.LOGIN.ERROR',
  },
  REGISTER: {
    REQUEST: 'USER.REGISTER.REQUEST',
    // we'll use USER.GET.SUCCESS on register success
    ERROR: 'USER.REGISTER.ERROR',
  },
  LOGOUT: {
    REQUEST: 'USER.LOGOUT.REQUEST',
    SUCCESS: 'USER.LOGOUT.SUCCESS',
    ERROR: 'USER.LOGOUT.ERROR',
  },
  PASSWORD_RESET_REQUEST: {
    REQUEST: 'USER.PASSWORD_RESET_REQUEST.REQUEST',
    SUCCESS: 'USER.PASSWORD_RESET_REQUEST.SUCCESS',
    ERROR: 'USER.PASSWORD_RESET_REQUEST.ERROR',
  },
  PASSWORD_RESET: {
    REQUEST: 'USER.PASSWORD_RESET.REQUEST',
    SUCCESS: 'USER.PASSWORD_RESET.SUCCESS',
    ERROR: 'USER.PASSWORD_RESET.ERROR',
  },
  CHECK_REGISTRATION: {
    REQUEST: 'USER.CHECK_REGISTRATION.REQUEST',
    SUCCESS: 'USER.CHECK_REGISTRATION.SUCCESS',
    ERROR: 'USER.CHECK_REGISTRATION.ERROR',
    CLEAR: 'USER.CHECK_REGISTRATION.CLEAR',
  },
  CLEAR: 'USER.CLEAR',
};
