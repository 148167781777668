import { TODO_FILE_PREVIEW } from './actionTypes';

export const fetchTodoFilePreview = (todoId, fileId, version) => ({
  type: TODO_FILE_PREVIEW.FETCH.REQUEST,
  payload: {
    todoId,
    fileId,
    version,
  },
});

export const fetchTodoFilePreviewSuccess = (file) => ({
  type: TODO_FILE_PREVIEW.FETCH.SUCCESS,
  payload: {
    file,
  },
});

export const fetchTodoFilePreviewError = (error) => ({
  type: TODO_FILE_PREVIEW.FETCH.ERROR,
  payload: {
    error,
  },
});

export const downloadTodoFilePreview = (fileId, todoId) => ({
  type: TODO_FILE_PREVIEW.DOWNLOAD.REQUEST,
  payload: {
    fileId,
    todoId,
  },
});

export const downloadTodoFilePreviewSuccess = () => ({
  type: TODO_FILE_PREVIEW.DOWNLOAD.SUCCESS,
});

export const downloadTodoFilePreviewError = (error) => ({
  type: TODO_FILE_PREVIEW.DOWNLOAD.ERROR,
  payload: {
    error,
  },
});

export const clearTodoFilePreview = () => ({
  type: TODO_FILE_PREVIEW.CLEAR,
});
