import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { constants } from '@hopkins_core/core_shared';

import { TODOS } from '../actions/actionTypes';
import {
  fetchTodoError,
  fetchTodoSuccess,
  completeTodoSuccess,
  completeTodoError,
} from '../actions/todos';
import { clearUploadedDocuments } from '../actions/todosDocumentUpload';
import { todosApi } from '../api/todos';

const { TODO_TYPES } = constants;

function* fetchTodo({ payload: { id } }) {
  try {
    const todo = yield call(todosApi.fetchTodo, { id });
    yield put(fetchTodoSuccess(todo));
  } catch (error) {
    yield put(fetchTodoError(id, error.message));
  }
}

function* completeTodo({
  payload: { todoId, inviteeUri, eventUri, caseId, date, operatorName },
}) {
  try {
    const todo = yield call(todosApi.completeTodo, {
      todoId,
      inviteeUri,
      eventUri,
      date,
      operatorName,
    });
    yield put(completeTodoSuccess(todo));
    if (todo.type === TODO_TYPES.DOCUMENT) {
      yield put(clearUploadedDocuments());
      yield put(push(`/cases/${caseId}/documents`));
    }
  } catch (e) {
    yield put(completeTodoError(todoId, e.message));
  }
}

const todosSagas = [
  takeEvery(TODOS.FETCH.REQUEST, fetchTodo),
  takeEvery(TODOS.COMPLETE.REQUEST, completeTodo),
];

export default todosSagas;
