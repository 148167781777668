import { GET, POST, PUT } from 'shared/utils/api';

export const todosApi = {
  fetchTodo: ({ id }) => GET(`/todos/${id}`),
  fetchTodosList: () => GET('/todos'),
  fetchOverview: () => GET('/todos/overview'),
  completeTodo: ({ todoId, inviteeUri, eventUri, date, operatorName }) =>
    PUT(`/todos/${todoId}/complete`, {
      inviteeUri,
      eventUri,
      date,
      operatorName,
    }),
  uploadDocument: ({ todoId, formData }) =>
    POST(`todos/${todoId}/upload`, formData, 60000),
  fetchTodoFilePreview: (todoId, fileId, version) =>
    GET(`/todos/${todoId}/files/${fileId}/${version}`, 60000),
  downloadFile: (fileId, todoId) =>
    GET(`/todos/${todoId}/files/${fileId}/preview/download`, 60000),
};
