import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { Loader } from 'shared/components';

import {
  selectUserId,
  selectCurrentUserIsLoading,
} from 'User/models/selectors/user';

function ProtectedRoute(props) {
  const { userId, isLoading, component: Component, render, ...rest } = props;

  if (isLoading) {
    return <Loader />;
  }

  const renderComponent = (props) => <Component {...props} />;

  const getRenderFn = () => {
    if (render) return render;
    if (Component) return renderComponent;
    return undefined;
  };

  if (userId) {
    return (
      <Route {...rest} render={getRenderFn()}>
        {props.children}
      </Route>
    );
  }
  // redirect if there is no user
  return <Redirect to='/login' />;
}

const mapStateToProps = (state) => ({
  userId: selectUserId(state),
  isLoading: selectCurrentUserIsLoading(state),
});

export default connect(mapStateToProps)(ProtectedRoute);
