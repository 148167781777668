import _ from 'lodash';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { selectFileById } from 'Documents/models/selectors/files';

import { TODO_FILE_PREVIEW } from '../actions/actionTypes';
import {
  fetchTodoFilePreviewSuccess,
  fetchTodoFilePreviewError,
  downloadTodoFilePreviewSuccess,
  downloadTodoFilePreviewError,
} from '../actions/todosFilePreview';
import { todosApi } from '../api/todos';

function* fetchFilePreview({ payload: { todoId, fileId, version } }) {
  try {
    const file = yield call(
      todosApi.fetchTodoFilePreview,
      todoId,
      fileId,
      version
    );
    yield put(fetchTodoFilePreviewSuccess(file));
  } catch (e) {
    yield put(fetchTodoFilePreviewError(e.message));
  }
}

function* downloadFile({ payload: { fileId, todoId } }) {
  try {
    const fileInfo = yield select(selectFileById(fileId));
    const buffer = yield call(todosApi.downloadFile, fileId, todoId);
    const blob = new Blob([Buffer.from(_.get(buffer, 'data', []))], {
      type: fileInfo.type,
    });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = _.get(fileInfo, 'name');
    link.click();
    link.remove();
    yield put(downloadTodoFilePreviewSuccess());
  } catch (e) {
    yield put(downloadTodoFilePreviewError(e.message));
  }
}

const todoFilePreviewSagas = [
  takeEvery(TODO_FILE_PREVIEW.FETCH.REQUEST, fetchFilePreview),
  takeEvery(TODO_FILE_PREVIEW.DOWNLOAD.REQUEST, downloadFile),
];

export default todoFilePreviewSagas;
