import _ from 'lodash';

import { CASE_DOCUMENTS } from '../actions/actionTypes';

const initialState = {
  result: { documents: [], files: [] },
  meta: {
    numberOfDocuments: 0,
    isLoading: true,
    error: null,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CASE_DOCUMENTS.FETCH.REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          isLoading: true,
        },
      };
    case CASE_DOCUMENTS.FETCH.SUCCESS:
      return {
        result: {
          documents: _.chain(action)
            .get(['payload', 'documents', 'result'])
            .map('_id')
            .value(),
          files: _.chain(action)
            .get(['payload', 'files', 'result'])
            .map('fileId')
            .value(),
        },
        meta: {
          ...state.meta,
          numberOfDocuments:
            _.chain(action)
              .get(['payload', 'documents', 'meta', 'numberOfDocuments'])
              .value() +
            _.chain(action)
              .get(['payload', 'files', 'meta', 'numberOfDocuments'])
              .value(),
          isLoading: false,
          error: null,
        },
      };

    case CASE_DOCUMENTS.FETCH.ERROR:
      return {
        result: { documents: [], files: [] },
        meta: {
          ...state.meta,
          numberOfDocuments: 0,
          isLoading: false,
          error: _.get(action, ['payload', 'error']),
        },
      };
    case CASE_DOCUMENTS.CLEAR:
      return initialState;
    default:
      return state;
  }
}
