export const CASES = {
  CLEAR: 'CASES.CLEAR',
};

export const CASES_LIST = {
  FETCH: {
    REQUEST: 'CASES_LIST.FETCH.REQUEST',
    SUCCESS: 'CASES_LIST.FETCH.SUCCESS',
    ERROR: 'CASES_LIST.FETCH.ERROR',
  },
  CLEAR: 'CASES_LIST.CLEAR',
};

export const CASES_OVERVIEW = {
  FETCH: {
    REQUEST: 'CASES_OVERVIEW.FETCH.REQUEST',
    SUCCESS: 'CASES_OVERVIEW.FETCH.SUCCESS',
    ERROR: 'CASES_OVERVIEW.FETCH.ERROR',
  },
  CLEAR: 'CASES_OVERVIEW.CLEAR',
};

export const CASE_DETAILS = {
  FETCH: {
    REQUEST: 'CASE_DETAILS.FETCH.REQUEST',
    SUCCESS: 'CASE_DETAILS.FETCH.SUCCESS',
    ERROR: 'CASE_DETAILS.FETCH.ERROR',
  },
  CLEAR: 'CASE_DETAILS.CLEAR',
};

export const CASE_DOCUMENTS = {
  FETCH: {
    REQUEST: 'CASE_DOCUMENTS.FETCH.REQUEST',
    SUCCESS: 'CASE_DOCUMENTS.FETCH.SUCCESS',
    ERROR: 'CASE_DOCUMENTS.FETCH.ERROR',
  },
  CLEAR: 'CASE_DOCUMENTS.CLEAR',
};

export const CASE_DOCUMENT_PREVIEW = {
  FETCH: {
    REQUEST: 'CASE_DOCUMENT_PREVIEW.FETCH.REQUEST',
    SUCCESS: 'CASE_DOCUMENT_PREVIEW.FETCH.SUCCESS',
    ERROR: 'CASE_DOCUMENT_PREVIEW.FETCH.ERROR',
  },
  DOWNLOAD: {
    REQUEST: 'CASE_DOCUMENT_PREVIEW.DOWNLOAD.REQUEST',
    SUCCESS: 'CASE_DOCUMENT_PREVIEW.DOWNLOAD.SUCCESS',
    ERROR: 'CASE_DOCUMENT_PREVIEW.DOWNLOAD.ERROR',
  },
  CLEAR: 'CASE_DOCUMENT_PREVIEW.CLEAR',
};

export const CASE_TODOS = {
  FETCH: {
    REQUEST: 'CASE_TODOS.FETCH.REQUEST',
    SUCCESS: 'CASE_TODOS.FETCH.SUCCESS',
    ERROR: 'CASE_TODOS.FETCH.ERROR',
  },
  CLEAR: 'CASE_TODOS.CLEAR',
};
