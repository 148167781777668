import _ from 'lodash';
import { createSelector } from 'reselect';

export const selectFileState = (state) => _.get(state, 'files');

export const selectFileById = _.curry((fileId, state) =>
  _.chain(state).thru(selectFileState).get(fileId).value()
);

const selectFileContent = (fileId, state) =>
  _.chain(state).thru(selectFileById(fileId)).get('content').value();

export const selectFileDataBuffer = createSelector(
  [selectFileContent],
  (content) =>
    content && {
      data: Buffer.from(content),
    }
);
