import { createStore, combineReducers, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import casesReducer from 'Cases/models/reducers/cases';
import casesOverviewReducer from 'Cases/models/reducers/casesOverview';
import casesListReducer from 'Cases/models/reducers/casesList';
import caseDetailsReducer from 'Cases/models/reducers/caseDetails';
import caseDocumentsReducer from 'Cases/models/reducers/caseDocuments';
import caseDocumentPreviewReducer from 'Cases/models/reducers/caseDocumentPreview';
import caseTodosReducer from 'Cases/models/reducers/caseTodos';
import documentsReducer from 'Documents/models/reducers/documents';
import filesReducer from 'Documents/models/reducers/files';
import todosReducer from 'Todos/models/reducers/todos';
import todosFilePreviewReducer from 'Todos/models/reducers/todosFilePreview';
import todosOverviewReducer from 'Todos/models/reducers/todosOverview';
import todosListReducer from 'Todos/models/reducers/todosList';
import todosDocumentUploadReducer from 'Todos/models/reducers/todosDocumentUpload';
import userReducer from 'User/models/reducers/user';

import casesOverviewSagas from 'Cases/models/sagas/casesOverview';
import casesListSagas from 'Cases/models/sagas/casesList';
import caseDetailsSagas from 'Cases/models/sagas/caseDetails';
import caseDocumentsSagas from 'Cases/models/sagas/caseDocuments';
import caseDocumentPreviewSagas from 'Cases/models/sagas/caseDocumentPreview';
import caseTodosSagas from 'Cases/models/sagas/caseTodos';
import todosSagas from 'Todos/models/sagas/todos';
import todosFilePreviewSagas from 'Todos/models/sagas/todosFilePreview';
import todosOverviewSagas from 'Todos/models/sagas/todosOverview';
import todosListSagas from 'Todos/models/sagas/todosList';
import todosDocumentUploadSagas from 'Todos/models/sagas/todosDocumentUpload';
import userSagas from 'User/models/sagas/user';
import { HOME_PAGE } from 'shared/enums';

// please keep reducers in alphabetical order
const createRootReducer = (history) =>
  combineReducers({
    caseDetails: caseDetailsReducer,
    caseDocumentPreview: caseDocumentPreviewReducer,
    caseDocuments: caseDocumentsReducer,
    caseTodos: caseTodosReducer,
    cases: casesReducer,
    casesList: casesListReducer,
    casesOverview: casesOverviewReducer,
    documents: documentsReducer,
    files: filesReducer,
    router: connectRouter(history),
    todos: todosReducer,
    todosFilePreview: todosFilePreviewReducer,
    todosList: todosListReducer,
    todosOverview: todosOverviewReducer,
    todosDocumentUpload: todosDocumentUploadReducer,
    user: userReducer,
  });

function* rootSaga() {
  yield all([
    ...casesOverviewSagas,
    ...casesListSagas,
    ...caseDetailsSagas,
    ...caseDocumentsSagas,
    ...caseDocumentPreviewSagas,
    ...caseTodosSagas,
    ...todosSagas,
    ...todosFilePreviewSagas,
    ...todosListSagas,
    ...todosOverviewSagas,
    ...todosDocumentUploadSagas,
    ...userSagas,
  ]);
}

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory({ basename: HOME_PAGE });

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        sagaMiddleware
      )
    )
  );

  sagaMiddleware.run(rootSaga);
  return store;
}
