import _ from 'lodash';

import { CASES_LIST } from '../actions/actionTypes';

const initialState = {
  result: [],
  meta: {
    numberOfDocuments: 0,
    isLoading: true,
    error: null,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CASES_LIST.FETCH.REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          isLoading: true,
        },
      };
    case CASES_LIST.FETCH.SUCCESS:
      return {
        result: _.chain(action)
          .get(['payload', 'cases', 'result'])
          .map('_id')
          .value(),
        meta: {
          ...state.meta,
          numberOfDocuments: _.chain(action)
            .get(['payload', 'cases', 'meta', 'numberOfDocuments'])
            .value(),
          isLoading: false,
          error: null,
        },
      };
    case CASES_LIST.FETCH.ERROR:
      return {
        result: [],
        meta: {
          ...state.meta,
          numberOfDocuments: 0,
          isLoading: false,
          error: _.get(action, ['payload', 'error']),
        },
      };
    case CASES_LIST.CLEAR:
      return initialState;
    default:
      return state;
  }
}
