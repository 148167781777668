import { CASES_OVERVIEW } from './actionTypes';

export const fetchCasesOverview = () => ({
  type: CASES_OVERVIEW.FETCH.REQUEST,
});

export const fetchCasesOverviewSuccess = (cases) => ({
  type: CASES_OVERVIEW.FETCH.SUCCESS,
  payload: {
    cases,
  },
});

export const fetchCasesOverviewError = (error) => ({
  type: CASES_OVERVIEW.FETCH.ERROR,
  payload: {
    error,
  },
});

export const clearCasesOverview = () => ({
  type: CASES_OVERVIEW.CLEAR,
});
