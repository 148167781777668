import { call, put, takeEvery } from 'redux-saga/effects';
import { CASE_TODOS } from '../actions/actionTypes';
import {
  fetchCaseTodosSuccess,
  fetchCaseTodosError,
} from '../actions/casesTodos';
import { casesApi } from '../api/cases';

function* fetchCaseTodos({ payload: { caseId } }) {
  try {
    const todos = yield call(casesApi.fetchCaseTodos, caseId);
    yield put(fetchCaseTodosSuccess(todos));
  } catch (e) {
    yield put(fetchCaseTodosError(e.message));
  }
}

const caseTodosSagas = [takeEvery(CASE_TODOS.FETCH.REQUEST, fetchCaseTodos)];

export default caseTodosSagas;
