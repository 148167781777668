import { CASE_DETAILS } from './actionTypes';

export const fetchCaseDetails = (caseId) => ({
  type: CASE_DETAILS.FETCH.REQUEST,
  payload: {
    caseId,
  },
});

export const fetchCaseDetailsSuccess = (matter) => ({
  type: CASE_DETAILS.FETCH.SUCCESS,
  payload: {
    matter,
  },
});

export const fetchCaseDetailsError = (error) => ({
  type: CASE_DETAILS.FETCH.ERROR,
  payload: {
    error,
  },
});

export const clearCaseDetails = () => ({
  type: CASE_DETAILS.CLEAR,
});
