import { call, put, takeEvery } from 'redux-saga/effects';
import { CASE_DETAILS } from '../actions/actionTypes';
import {
  fetchCaseDetailsSuccess,
  fetchCaseDetailsError,
} from '../actions/caseDetails';
import { casesApi } from '../api/cases';

function* fetchCaseDetails({ payload: { caseId } }) {
  try {
    const matter = yield call(casesApi.fetchCaseDetails, caseId);
    yield put(fetchCaseDetailsSuccess(matter));
  } catch (e) {
    yield put(fetchCaseDetailsError(e.message));
  }
}

const caseDetailsSagas = [
  takeEvery(CASE_DETAILS.FETCH.REQUEST, fetchCaseDetails),
];

export default caseDetailsSagas;
