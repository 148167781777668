import { call, put, takeEvery } from 'redux-saga/effects';
import { TODOS_LIST } from '../actions/actionTypes';
import {
  fetchTodosListSuccess,
  fetchTodosListError,
} from '../actions/todosList';
import { todosApi } from '../api/todos';

function* fetchList() {
  try {
    const todos = yield call(todosApi.fetchTodosList);
    yield put(fetchTodosListSuccess(todos));
  } catch (e) {
    yield put(fetchTodosListError(e.message));
  }
}

const todosListSagas = [takeEvery(TODOS_LIST.FETCH.REQUEST, fetchList)];

export default todosListSagas;
