import _ from 'lodash';

import { CASE_DOCUMENTS } from 'Cases/models/actions/actionTypes';
import { TODO_FILE_PREVIEW } from 'Todos/models/actions/actionTypes';

import { FILES } from '../actions/actionTypes';

const initialState = {
  // Keeps files indexed by fileId:
  // [_id]: FileObject
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CASE_DOCUMENTS.FETCH.SUCCESS:
      return {
        ...state,
        ..._.chain(action)
          .get(['payload', 'files', 'result'])
          .keyBy('fileId')
          .value(),
      };

    case TODO_FILE_PREVIEW.FETCH.SUCCESS:
      return {
        ...state,
        [_.get(action, ['payload', 'file', 'fileId'])]: {
          ..._.get(state, action.payload.file.fileId),
          content: _.get(action, ['payload', 'file', 'content']),
        },
      };
    case FILES.CLEAR:
      return initialState;
    default:
      return state;
  }
}
