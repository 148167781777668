import _ from 'lodash';
import { createSelector } from 'reselect';

export const selectDocumentState = (state) => _.get(state, 'documents');

export const selectDocumentById = _.curry((documentId, state) =>
  _.chain(state).thru(selectDocumentState).get(documentId).value()
);

const selectDocumentContent = (documentId, state) =>
  _.chain(state).thru(selectDocumentById(documentId)).get('content').value();

export const selectDocumentFile = createSelector(
  [selectDocumentContent],
  (content) =>
    content && {
      data: Buffer.from(content),
    }
);
