import { put, call, takeEvery } from 'redux-saga/effects';
import { CASES_LIST } from '../actions/actionTypes';
import {
  fetchCasesListSuccess,
  fetchCasesListError,
} from '../actions/casesList';
import { casesApi } from '../api/cases';

function* fetchList() {
  try {
    const cases = yield call(casesApi.fetchCasesList);
    yield put(fetchCasesListSuccess(cases));
  } catch (e) {
    yield put(fetchCasesListError(e.message));
  }
}

const casesListSagas = [takeEvery(CASES_LIST.FETCH.REQUEST, fetchList)];

export default casesListSagas;
