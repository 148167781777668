import { Alert, Space } from 'antd';
import _ from 'lodash';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BackNavigation, Header, Input, Button } from 'shared/components';
import { requestPasswordReset } from 'User/models/actions/user';
import {
  selectPasswordResetRequestError,
  selectPasswordResetRequestIsLoading,
  selectPasswordResetRequestIsSuccess,
} from 'User/models/selectors/user';

import '../Login/Login.scss';

function RequestPasswordReset({
  requestPasswordReset,
  error,
  isLoading,
  isSuccess,
}) {
  const [email, setEmail] = useState();
  let history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    requestPasswordReset({ email });
  };

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleGoBack = () => {
    history.push('/login');
  };

  return (
    <>
      <Header />
      <div className={`login-container ${isMobile ? 'mobile' : 'browser'}`}>
        <BackNavigation className='back-button' onGoBack={handleGoBack} />
        <h1>Passwort vergessen?</h1>
        <div className='description'>
          Geben Sie hier Ihre E-Mail Adresse ein, um Ihr Passwort
          zurückzusetzen.
        </div>
        {!_.isNil(error) && <Alert message={error} type='error' showIcon />}
        <form className='login-form' onSubmit={handleSubmit}>
          <div>
            <Space direction='vertical' className='input-space'>
              <label htmlFor='email'> E-Mail-Adresse</label>
              <Input
                name='username'
                size='large'
                value={email}
                onChange={handleEmailChange}
              />
            </Space>
          </div>
          {isSuccess ? (
            <>
              <span>
                Wenn die eingegebene E-Mail-Adresse mit einem Konto
                übereinstimmt, erhalten Sie in Kürze eine E-Mail mit einem Link,
                über den Sie Ihr Passwort zurücksetzen können.
              </span>
            </>
          ) : (
            <Button
              type='primary'
              htmlType='submit'
              size='large'
              loading={isLoading}
            >
              Zurücksetzen
            </Button>
          )}
        </form>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  error: selectPasswordResetRequestError(state),
  isLoading: selectPasswordResetRequestIsLoading(state),
  isSuccess: selectPasswordResetRequestIsSuccess(state),
});

const mapDispatchToProps = {
  requestPasswordReset,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestPasswordReset);
