import { CASE_TODOS } from './actionTypes';

export const fetchCaseTodos = (caseId) => ({
  type: CASE_TODOS.FETCH.REQUEST,
  payload: {
    caseId,
  },
});

export const fetchCaseTodosSuccess = (todos) => ({
  type: CASE_TODOS.FETCH.SUCCESS,
  payload: {
    todos,
  },
});

export const fetchCaseTodosError = (error) => ({
  type: CASE_TODOS.FETCH.ERROR,
  payload: {
    error,
  },
});

export const clearCaseTodos = () => ({
  type: CASE_TODOS.CLEAR,
});
