import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './Loader.scss';

export default function Loader(props) {
  const { size = 'default' } = props;

  return (
    <div className='loader-container'>
      <Spin className='loader' indicator={<LoadingOutlined spin />} size={size} />
    </div>
  );
}
