import _ from 'lodash';

import { CASE_DETAILS } from '../actions/actionTypes';

const initialState = {
  isLoading: true,
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CASE_DETAILS.FETCH.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CASE_DETAILS.FETCH.SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case CASE_DETAILS.FETCH.ERROR:
      return {
        ...state,
        isLoading: false,
        error: _.get(action, ['payload', 'error']),
      };
    case CASE_DETAILS.CLEAR:
      return initialState;
    default:
      return state;
  }
}
