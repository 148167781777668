import { TODOS } from './actionTypes';

export const fetchTodo = (id) => ({
  type: TODOS.FETCH.REQUEST,
  payload: { id },
});

export const fetchTodoSuccess = (todos) => ({
  type: TODOS.FETCH.SUCCESS,
  payload: {
    todos,
  },
});

export const fetchTodoError = (id, error) => ({
  type: TODOS.FETCH.ERROR,
  payload: { id, error },
});

export const completeTodo = ({
  todoId,
  inviteeUri,
  eventUri,
  caseId,
  date,
  operatorName,
}) => ({
  type: TODOS.COMPLETE.REQUEST,
  payload: {
    todoId,
    inviteeUri,
    eventUri,
    caseId,
    date,
    operatorName,
  },
});

export const completeTodoSuccess = (todo) => ({
  type: TODOS.COMPLETE.SUCCESS,
  payload: {
    todo,
  },
});

export const completeTodoError = (todoId, error) => ({
  type: TODOS.COMPLETE.ERROR,
  payload: {
    todoId,
    error,
  },
});

export const clearTodos = () => ({
  type: TODOS.CLEAR,
});
