import { Fragment } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import './Breadcrumbs.scss';

/**
 * Items should have the following structure:
 * [
 *   {
 *     key: 'myCases', // unique
 *     label: 'Meine Fälle',
 *     link: '/cases,
 *   },
 *   {
 *     key: 'case',
 *     label: 'Kündigung erhalten',
 *     isActive: true, // means it is the current page
 *   }
 * ]
 */
export default function Breadcrumbs({ items = [] }) {
  const history = useHistory();

  const renderItem = (item) => (
    <Fragment key={item.key}>
      <a className='text-link' onClick={() => history.push(item.link)}>
        {item.label}
      </a>
      <RightOutlined className='breadcrumbs-icon' />
    </Fragment>
  );

  const renderActiveItem = (item) => (
    <span className='breadcrumbs-current'>{item.label}</span>
  );

  return (
    <div className={`breadcrumbs-container ${isMobile ? 'mobile' : 'browser'}`}>
      {items.filter(({ isActive }) => !isActive).map(renderItem)}
      {renderActiveItem(items.find(({ isActive }) => isActive))}
    </div>
  );
}
