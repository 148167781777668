import _ from 'lodash';

import {
  CASE_DOCUMENTS,
  CASE_DOCUMENT_PREVIEW,
} from 'Cases/models/actions/actionTypes';

import { DOCUMENTS } from '../actions/actionTypes';

const initialState = {
  // Keeps documents indexed by _id:
  // [_id]: DocumentObject
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CASE_DOCUMENTS.FETCH.SUCCESS:
      return {
        ...state,
        ..._.chain(action)
          .get(['payload', 'documents', 'result'])
          .map((document) => ({
            ...document,
            content: _.get(state, `${document._id}.content`), // caching the file
          }))
          .keyBy('_id')
          .value(),
      };

    case CASE_DOCUMENT_PREVIEW.FETCH.SUCCESS:
      return {
        ...state,
        [_.get(action, ['payload', 'document', '_id'])]: _.get(action, [
          'payload',
          'document',
        ]),
      };
    case DOCUMENTS.CLEAR:
      return initialState;
    default:
      return state;
  }
}
