import { connect } from 'react-redux';
import { ExportOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';

import { selectIsLoggedOut } from 'User/models/selectors/user';

import { logout, clearUser } from '../../models/actions/user';

import './Logout.scss';

function Logout(props) {
  const { isLoggedOut, logout, clearUser } = props;

  if (isLoggedOut) {
    clearUser();
    window.location.href = 'https://hopkins.law';
  }

  return (
    <div className='logout-container' role='button' onClick={logout}>
      <ExportOutlined className='logout-icon' />
      {!isMobile && <span className='logout-label'>Abmelden</span>}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoggedOut: selectIsLoggedOut(state),
});

const mapDispatchToProps = {
  logout,
  clearUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
