import React, { useState } from 'react';
import { Input } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import { passwordRegex } from 'User/utils';

import PasswordTips from './PasswordTips';

import './PasswordInput.scss';

const PasswordInput = React.forwardRef(function PassWordInputWithRef(
  {
    password,
    placeholder,
    size = 'large',
    name = 'password',
    value,
    disabled,
    onChange,
    onBlur = () => {},
    hasError = false,
    showTips = false,
  },
  ref
) {
  const [isBlurred, setIsBlurred] = useState(false);

  const handleBlur = () => {
    setIsBlurred(true);
    onBlur();
  };

  const handleFocus = () => {
    setIsBlurred(false);
  };

  return (
    <div className='input-container'>
      <div className='password-input-wrapper'>
        <Input.Password
          className={`CP-input-password${hasError ? ' input-error' : ''}`}
          status={hasError && isBlurred ? 'error' : ''}
          ref={ref}
          size={size}
          name={name}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
        {showTips && passwordRegex.test(password) && (
          <CheckCircleOutlined className='password-check-icon' />
        )}
      </div>
      {showTips && !passwordRegex.test(password) && (
        <PasswordTips isBlurred={isBlurred} password={password} />
      )}
    </div>
  );
});

export default PasswordInput;
