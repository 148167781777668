import { call, put, takeEvery } from 'redux-saga/effects';
import { CASES_OVERVIEW } from '../actions/actionTypes';
import {
  fetchCasesOverviewSuccess,
  fetchCasesOverviewError,
} from '../actions/casesOverview';
import { casesApi } from '../api/cases';

function* fetchOverview() {
  try {
    const cases = yield call(casesApi.fetchOverview);
    yield put(fetchCasesOverviewSuccess(cases));
  } catch (e) {
    yield put(fetchCasesOverviewError(e.message));
  }
}

const casesOverviewSagas = [
  takeEvery(CASES_OVERVIEW.FETCH.REQUEST, fetchOverview),
];

export default casesOverviewSagas;
