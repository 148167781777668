export const TODOS = {
  COMPLETE: {
    REQUEST: 'TODOS.COMPLETE.REQUEST',
    SUCCESS: 'TODOS.COMPLETE.SUCCESS',
    ERROR: 'TODOS.COMPLETE.ERROR',
  },
  CLEAR: 'TODOS.CLEAR',
  FETCH: {
    REQUEST: 'TODOS.FETCH.REQUEST',
    SUCCESS: 'TODOS.FETCH.SUCCESS',
    ERROR: 'TODOS.FETCH.ERROR',
  },
};

export const TODOS_OVERVIEW = {
  FETCH: {
    REQUEST: 'TODOS_OVERVIEW.FETCH.REQUEST',
    SUCCESS: 'TODOS_OVERVIEW.FETCH.SUCCESS',
    ERROR: 'TODOS_OVERVIEW.FETCH.ERROR',
  },
  CLEAR: 'TODOS_OVERVIEW.CLEAR',
};

export const TODOS_LIST = {
  FETCH: {
    REQUEST: 'TODOS_LIST.FETCH.REQUEST',
    SUCCESS: 'TODOS_LIST.FETCH.SUCCESS',
    ERROR: 'TODOS_LIST.FETCH.ERROR',
  },
  CLEAR: 'TODOS_LIST.CLEAR',
};

export const TODOS_DOCUMENT_UPLOAD = {
  REQUEST: 'TODOS_DOCUMENT_UPLOAD.REQUEST',
  SUCCESS: 'TODOS_DOCUMENT_UPLOAD.SUCCESS',
  ERROR: 'TODOS_DOCUMENT_UPLOAD.ERROR',
  CLEAR: 'TODOS_DOCUMENT_UPLOAD.CLEAR',
};

export const TODO_FILE_PREVIEW = {
  FETCH: {
    REQUEST: 'TODO_PREVIEW.FETCH.REQUEST',
    SUCCESS: 'TODO_PREVIEW.FETCH.SUCCESS',
    ERROR: 'TODO_PREVIEW.FETCH.ERROR',
  },
  DOWNLOAD: {
    REQUEST: 'TODO_PREVIEW.DOWNLOAD.REQUEST',
    SUCCESS: 'TODO_PREVIEW.DOWNLOAD.SUCCESS',
    ERROR: 'TODO_PREVIEW.DOWNLOAD.ERROR',
  },
  CLEAR: 'TODO_PREVIEW.CLEAR',
};
