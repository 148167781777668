import { useEffect } from 'react';
import { Alert, Form } from 'antd';
import _ from 'lodash';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Header, PasswordInput, Button } from 'shared/components';
import { useQuery } from 'shared/hooks';

import { resetPassword, getUser } from '../../models/actions/user';
import {
  selectPasswordResetError,
  selectPasswordResetIsLoading,
  selectPasswordResetIsSuccess,
} from '../../models/selectors/user';
import { passwordRegex } from '../../utils';
import '../Login/Login.scss';

function ResetPassword({
  error,
  isSuccess,
  isLoading,
  resetPassword,
  getUser,
}) {
  const [password, setPassword] = useState('');
  let history = useHistory();
  const token = useQuery().get('token');

  useEffect(() => {
    if (isSuccess) {
      getUser();
    }
  }, [isSuccess, getUser]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = () => {
    resetPassword({ token, password });
  };

  const handleClick = () => history.push('/');

  return (
    <>
      <Header />
      <div
        className={`login-container password-reset ${
          isMobile ? 'mobile' : 'browser'
        }`}
      >
        {isSuccess ? (
          <>
            <h1>Ihr Passwort wurde erfolgreich zurückgesetzt!</h1>
            <Button type='primary' size='large' onClick={handleClick}>
              Einloggen
            </Button>
          </>
        ) : (
          <>
            <h1>Ihr Passwort zurücksetzen</h1>
            <div className='description'>
              Geben Sie Ihr neues Passwort ein und tippen Sie auf Passwort
              ändern.
            </div>
            {!_.isNil(error) && (
              <Alert
                message='Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.'
                type='error'
                showIcon
              />
            )}
            <Form
              layout='vertical'
              name='passwordReset'
              onFinish={handleSubmit}
              requiredMark={false}
            >
              <Form.Item label='Neues Passwort' name='password'>
                <PasswordInput
                  size='large'
                  value={password}
                  onChange={handlePasswordChange}
                  password={password}
                  hasError={!passwordRegex.test(password)}
                  showTips
                />
              </Form.Item>
              <Form.Item>
                <Button
                  disabled={!passwordRegex.test(password)}
                  type='primary'
                  htmlType='submit'
                  size='large'
                  loading={isLoading}
                >
                  Passwort ändern
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  error: selectPasswordResetError(state),
  isLoading: selectPasswordResetIsLoading(state),
  isSuccess: selectPasswordResetIsSuccess(state),
});

const mapDispatchToProps = {
  resetPassword,
  getUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
