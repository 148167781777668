import _ from 'lodash';

import { TODOS_DOCUMENT_UPLOAD } from '../actions/actionTypes';
import { DOCUMENT_STATUSES } from 'Todos/models/enums';

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TODOS_DOCUMENT_UPLOAD.REQUEST: {
      const todoId = _.get(action, ['payload', 'todoId']);
      const file = _.get(action, ['payload', 'file']);
      return {
        ...state,
        [todoId]: {
          ...state[todoId],
          [file.uid]: {
            ...file,
            status: DOCUMENT_STATUSES.UPLOADING,
          },
        },
      };
    }
    case TODOS_DOCUMENT_UPLOAD.SUCCESS: {
      const todoId = _.get(action, ['payload', 'todoId']);
      const fileId = _.get(action, ['payload', 'uid']);
      return {
        ...state,
        [todoId]: {
          ...state[todoId],
          [fileId]: {
            ...state[todoId][fileId],
            status: DOCUMENT_STATUSES.DONE,
          },
        },
      };
    }
    case TODOS_DOCUMENT_UPLOAD.ERROR: {
      const todoId = _.get(action, ['payload', 'todoId']);
      const fileId = _.get(action, ['payload', 'uid']);
      const message = _.get(action, ['payload', 'message']);
      return {
        ...state,
        [todoId]: {
          ...state[todoId],
          [fileId]: {
            ...state[todoId][fileId],
            status: DOCUMENT_STATUSES.ERROR,
            message,
          },
        },
      };
    }
    case TODOS_DOCUMENT_UPLOAD.CLEAR:
      return initialState;
    default:
      return state;
  }
}
