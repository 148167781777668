import { CASE_DOCUMENT_PREVIEW } from './actionTypes';

export const fetchCaseDocumentPreview = (caseId, documentId) => ({
  type: CASE_DOCUMENT_PREVIEW.FETCH.REQUEST,
  payload: {
    caseId,
    documentId,
  },
});

export const fetchCaseDocumentPreviewSuccess = (document) => ({
  type: CASE_DOCUMENT_PREVIEW.FETCH.SUCCESS,
  payload: {
    document,
  },
});

export const fetchCaseDocumentPreviewError = (error) => ({
  type: CASE_DOCUMENT_PREVIEW.FETCH.ERROR,
  payload: {
    error,
  },
});

export const downloadCaseDocumentPreview = (caseId, documentId) => ({
  type: CASE_DOCUMENT_PREVIEW.DOWNLOAD.REQUEST,
  payload: {
    caseId,
    documentId,
  },
});

export const downloadCaseDocumentPreviewSuccess = () => ({
  type: CASE_DOCUMENT_PREVIEW.DOWNLOAD.SUCCESS,
});

export const downloadCaseDocumentPreviewError = (error) => ({
  type: CASE_DOCUMENT_PREVIEW.DOWNLOAD.ERROR,
  payload: {
    error,
  },
});

export const clearCaseDocumentPreview = () => ({
  type: CASE_DOCUMENT_PREVIEW.CLEAR,
});
