import { isMobile } from 'react-device-detect';
import { PhoneOutlined } from '@ant-design/icons';

import './Footer.scss';

export default function Footer() {
  return (
    <footer className={`footer-container ${isMobile ? 'mobile' : 'browser'}`}>
      <span>Soforthilfe (kostenfrei):</span>
      <a href='tel:+4930609898188' className='phone-container'>
        <div className='phone-icon-container'>
          <PhoneOutlined className='phone-icon' />
        </div>
        030 60 9898188
      </a>
    </footer>
  );
}
