import React from 'react';
import { Input as AntdInput } from 'antd';

import './Input.scss';

const Input = React.forwardRef(function InputWithRef(
  {
    placeholder,
    size = 'large',
    name,
    defaultValue,
    value,
    disabled,
    onChange,
  },
  ref
) {
  return (
    <div className='input-container'>
      <AntdInput
        className='CP-input'
        ref={ref}
        size={size}
        name={name}
        disabled={disabled}
        defaultValue={defaultValue}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
});

export default Input;
