import { isMobile } from 'react-device-detect';
import { RightOutlined } from '@ant-design/icons';

import { Button } from 'shared/components';

import './Error.scss';

export default function Error(props) {
  const { error, onRetry } = props;

  return (
    <div className='error-container'>
      <div className='error-icon'>Ups!</div>
      <div className='error-message'>{error}</div>
      {isMobile
        ? (
          <span className='action-text'>
            Erneut versuchen
            <RightOutlined />
          </span>
        ) : (
          <Button onClick={onRetry}>Erneut versuchen</Button>
        )
      }
    </div>
  );
}