import { call, put, takeEvery } from 'redux-saga/effects';
import { TODOS_OVERVIEW } from '../actions/actionTypes';
import {
  fetchTodosOverviewSuccess,
  fetchTodosOverviewError,
} from '../actions/todosOverview';
import { todosApi } from '../api/todos';

function* fetchOverview() {
  try {
    const todos = yield call(todosApi.fetchOverview);
    yield put(fetchTodosOverviewSuccess(todos));
  } catch (e) {
    yield put(fetchTodosOverviewError(e.message));
  }
}

const todosOverviewSagas = [
  takeEvery(TODOS_OVERVIEW.FETCH.REQUEST, fetchOverview),
];

export default todosOverviewSagas;
