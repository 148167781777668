import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import Logout from 'User/components/Logout/Logout';

import { ReactComponent as HopkinsLogo } from './Hopkins_Logo.svg';
import './Header.scss';

export default function Header(props) {
  const { hasLogout = false } = props;

  const history = useHistory();

  const handleLogoClick = () => history.push('/');

  return (
    <header className={`header-container ${isMobile ? 'mobile' : 'browser'}`}>
      <HopkinsLogo className='hopkins-logo' onClick={handleLogoClick} />
      {hasLogout && <Logout />}
    </header>
  );
}
