import _ from 'lodash';

import { CASE_TODOS } from 'Cases/models/actions/actionTypes';

import { TODOS, TODOS_OVERVIEW, TODOS_LIST } from '../actions/actionTypes';

const initialState = {
  // Keeps todos indexed by _id:
  // [_id]: TodoObject
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TODOS_OVERVIEW.FETCH.SUCCESS:
    case TODOS_LIST.FETCH.SUCCESS:
    case CASE_TODOS.FETCH.SUCCESS:
      return {
        ...state,
        ..._.chain(action)
          .get(['payload', 'todos', 'result'])
          .keyBy('_id')
          .value(),
      };
    case TODOS.FETCH.REQUEST: {
      const todoId = _.get(action, ['payload', 'id']);
      return {
        ...state,
        [todoId]: { isLoading: true, error: null },
      };
    }
    case TODOS.FETCH.SUCCESS: {
      const todo = _.get(action, ['payload', 'todos']);
      return {
        ...state,
        [_.get(todo, '_id')]: { ...todo, isLoading: false, error: null },
      };
    }
    case TODOS.FETCH.ERROR: {
      const todoId = _.get(action, ['payload', 'id']);
      return {
        ...state,
        [todoId]: {
          isLoading: false,
          error: _.get(action, ['payload', 'error']),
        },
      };
    }
    case TODOS.COMPLETE.REQUEST: {
      const todoId = _.get(action, ['payload', 'todoId']);
      return {
        ...state,
        [todoId]: {
          ...state[todoId],
          isUpdating: true,
        },
      };
    }
    case TODOS.COMPLETE.SUCCESS: {
      const todo = _.get(action, ['payload', 'todo']);
      return {
        ...state,
        [todo._id]: {
          ...state[todo._id],
          ...todo,
          isUpdating: false,
        },
      };
    }
    case TODOS.COMPLETE.ERROR: {
      const todoId = _.get(action, ['payload', 'todoId']);
      return {
        ...state,
        [todoId]: {
          ...state[todoId],
          isUpdating: false,
        },
      };
    }
    case TODOS.CLEAR:
      return initialState;
    default:
      return state;
  }
}
