import { call, put, takeEvery } from 'redux-saga/effects';
import { TODOS_DOCUMENT_UPLOAD } from '../actions/actionTypes';
import {
  uploadDocumentSuccess,
  uploadDocumentError,
} from '../actions/todosDocumentUpload';
import { todosApi } from '../api/todos';

// using file instead of document as we need file name and status data before there is a documentId
function* uploadDocument({ payload: { todoId, formData, file } }) {
  try {
    yield call(todosApi.uploadDocument, { todoId, formData });
    yield put(uploadDocumentSuccess(todoId, file.uid));
  } catch (e) {
    yield put(uploadDocumentError(todoId, e.message, file.uid));
  }
}

const todosDocumentUploadSagas = [
  takeEvery(TODOS_DOCUMENT_UPLOAD.REQUEST, uploadDocument),
];

export default todosDocumentUploadSagas;
