import _ from 'lodash';

import {
  CASES,
  CASES_OVERVIEW,
  CASES_LIST,
  CASE_DETAILS,
} from '../actions/actionTypes';

const initialState = {
  // Keeps cases indexed by _id:
  // [_id]: CaseObject
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CASES_OVERVIEW.FETCH.SUCCESS:
    case CASES_LIST.FETCH.SUCCESS:
      return {
        ...state,
        ..._.chain(action)
          .get(['payload', 'cases', 'result'])
          .keyBy('_id')
          .value(),
      };
    case CASE_DETAILS.FETCH.SUCCESS:
      return {
        ...state,
        [_.get(action, ['payload', 'matter', '_id'])]: _.get(action, [
          'payload',
          'matter',
        ]),
      };
    case CASES.CLEAR:
      return initialState;
    default:
      return state;
  }
}
