import React from 'react';
import _ from 'lodash';
import { Menu } from 'antd';
import { TabBar } from 'antd-mobile';
import { isMobile } from 'react-device-detect';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  HomeOutlined,
  RocketOutlined,
  FolderAddOutlined,
} from '@ant-design/icons';
import { constants } from '@hopkins_core/core_shared';

import { ReactComponent as MatterOutlined } from 'shared/icons/matter-outlined.svg';

import './Menu.scss';

const { BASE_APP_URL } = constants;

const MENU_ITEMS = {
  HOME: {
    key: '',
    title: 'Überblick',
    icon: <HomeOutlined />,
  },
  CASES: {
    key: 'cases',
    title: 'Meine Fälle',
    icon: <MatterOutlined />,
  },
  TODOS: {
    key: 'todos',
    title: 'Meine To-Dos',
    icon: <RocketOutlined />,
  },
  NEW_CASE: {
    key: 'newCase',
    title: 'Neuer Fall',
    icon: <FolderAddOutlined />,
    hasVerticalBorder: true,
  },
};

// set todoId to show the "Back to ClientPortal" button at the end of the Form
const CLAIM_CHECK_URL = `${BASE_APP_URL}/fallpruefung?todoId=new-case`;

export default function MainMenu(props) {
  const { hide } = props;

  const history = useHistory();
  const match = useRouteMatch('/:currentRoute');
  const currentRoute = _.get(match, ['params', 'currentRoute'], '');

  const selectedMenuItem = _.chain(MENU_ITEMS)
    .values()
    .find(['key', currentRoute])
    .get('key')
    .value();

  const handleBrowserMenuItemClick = (key) => () =>
    key === MENU_ITEMS.NEW_CASE.key
      ? window.location.assign(CLAIM_CHECK_URL)
      : history.push(`/${key}`);

  const handleMobileMenuItemClick = (key) =>
    key === MENU_ITEMS.NEW_CASE.key
      ? window.location.assign(CLAIM_CHECK_URL)
      : history.push(`/${key}`);

  const renderMobileMenuItem = ({ key, title, icon }) => (
    <TabBar.Item
      className='mobile-menu__item'
      key={key}
      title={title}
      icon={icon}
      selected={key === currentRoute}
    />
  );

  const renderBrowserMenuItem = ({
    key,
    title,
    icon,
    hasVerticalBorder = false,
  }) => (
    <Menu.Item
      className={`browser-menu__item${hasVerticalBorder ? ' hasBorder' : ''}`}
      key={key}
      icon={icon}
      onClick={handleBrowserMenuItemClick(key)}
    >
      {title}
    </Menu.Item>
  );

  return (
    <div
      className={`main-menu ${isMobile ? 'mobile' : 'browser'}${
        hide ? ' hide' : ''
      }`}
    >
      {isMobile ? (
        <TabBar
          className='mobile-menu'
          activeKey={selectedMenuItem}
          onChange={handleMobileMenuItemClick}
        >
          {Object.values(MENU_ITEMS).map(renderMobileMenuItem)}
        </TabBar>
      ) : (
        <Menu
          className='browser-menu'
          mode='inline'
          selectedKeys={[selectedMenuItem]}
        >
          {Object.values(MENU_ITEMS).map(renderBrowserMenuItem)}
        </Menu>
      )}
    </div>
  );
}
