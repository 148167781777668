import { GET } from 'shared/utils/api';

export const casesApi = {
  fetchCasesList: () => GET('/cases'),
  fetchOverview: () => GET('/cases/overview'),
  fetchCaseDetails: (caseId) => GET(`/cases/${caseId}`),
  fetchCaseTodos: (caseId) => GET(`/cases/${caseId}/todos`),
  fetchCaseDocuments: (caseId) => GET(`/cases/${caseId}/documents`),
  fetchCaseDocumentPreview: (caseId, documentId) =>
    GET(`/cases/${caseId}/documents/${documentId}`, 60000),
  downloadDocument: (caseId, documentId) =>
    GET(`/cases/${caseId}/documents/${documentId}/download`, 60000),
  fetchCaseFiles: (caseId) => GET(`/cases/${caseId}/files`),
};
