import { TODOS_DOCUMENT_UPLOAD } from './actionTypes';

export const uploadDocument = (todoId, formData, file) => ({
  type: TODOS_DOCUMENT_UPLOAD.REQUEST,
  payload: {
    todoId,
    formData,
    file,
  },
});

export const uploadDocumentSuccess = (todoId, uid) => ({
  type: TODOS_DOCUMENT_UPLOAD.SUCCESS,
  payload: {
    todoId,
    uid,
  },
});

export const uploadDocumentError = (todoId, message, uid) => ({
  type: TODOS_DOCUMENT_UPLOAD.ERROR,
  payload: {
    todoId,
    message,
    uid,
  },
});

export const clearUploadedDocuments = () => ({
  type: TODOS_DOCUMENT_UPLOAD.CLEAR,
});
