import { put, takeEvery, call } from 'redux-saga/effects';

import { CASE_DOCUMENTS } from '../actions/actionTypes';
import {
  fetchCaseDocumentsSuccess,
  fetchCaseDocumentsError,
} from '../actions/caseDocuments';
import { casesApi } from '../api/cases';

function* fetchCaseDocuments({ payload: { caseId } }) {
  try {
    const files = yield call(casesApi.fetchCaseFiles, caseId);
    const documents = yield call(casesApi.fetchCaseDocuments, caseId);
    yield put(fetchCaseDocumentsSuccess({ documents, files }));
  } catch (e) {
    yield put(fetchCaseDocumentsError(e.message));
  }
}

const caseDocumentsSagas = [
  takeEvery(CASE_DOCUMENTS.FETCH.REQUEST, fetchCaseDocuments),
];

export default caseDocumentsSagas;
