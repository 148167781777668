import _ from 'lodash';

import { TODO_FILE_PREVIEW } from '../actions/actionTypes';

const initialState = {
  isLoading: true,
  isDownloading: false,
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TODO_FILE_PREVIEW.FETCH.REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case TODO_FILE_PREVIEW.FETCH.SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case TODO_FILE_PREVIEW.FETCH.ERROR:
      return {
        ...state,
        isLoading: false,
        error: _.get(action, ['payload', 'error']),
      };
    case TODO_FILE_PREVIEW.DOWNLOAD.REQUEST:
      return {
        ...state,
        isDownloading: true,
      };
    case TODO_FILE_PREVIEW.DOWNLOAD.SUCCESS:
    case TODO_FILE_PREVIEW.DOWNLOAD.ERROR:
      return {
        ...state,
        isDownloading: false,
      };
    case TODO_FILE_PREVIEW.CLEAR:
      return initialState;
    default:
      return state;
  }
}
