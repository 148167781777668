import { CASES_LIST } from './actionTypes';

export const fetchCasesList = () => ({
  type: CASES_LIST.FETCH.REQUEST,
});

export const fetchCasesListSuccess = (cases) => ({
  type: CASES_LIST.FETCH.SUCCESS,
  payload: {
    cases,
  },
});

export const fetchCasesListError = (error) => ({
  type: CASES_LIST.FETCH.SUCCESS,
  payload: {
    error,
  },
});

export const clearCasesList = () => ({
  type: CASES_LIST.CLEAR,
});
