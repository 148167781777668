import { useEffect } from 'react';
import { connect } from 'react-redux';

import { getUser } from 'User/models/actions/user';

import Routes from './Router/Routes';

import './App.scss';

function App(props) {
  const { getUser } = props;

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <div className='app-container'>
      <Routes />
    </div>
  );
}

const mapDispatchToProps = {
  getUser,
};

export default connect(null, mapDispatchToProps)(App);
